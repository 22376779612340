<!-- 合同编辑页 -->
<template>
  <div>
    <blockTitle title="合同编辑页"></blockTitle>
    <el-card shadow="never">
      <div class="operator mb-4 border-b pb-4">
        <el-button @click="submit" size="small" type="primary"
        >保存合同
        </el-button>
        <el-button @click="goBack" size="small">取消编辑</el-button>
      </div>
      <div class="card-content">
        <el-form
            ref="form"
            :model="agreementObj"
            label-width="160px"
            label-position="left"
            size="small"
            cass="overflow-auto"
            :rules="rules"
        >
          <el-form-item label="合同名称" prop="agreementName">
            <el-input v-model="agreementObj.agreementName"></el-input>
          </el-form-item>
          <el-form-item label="合同编号" prop="agreementNo">
            <el-input v-model="agreementObj.agreementNo"></el-input>
          </el-form-item>
          <el-form-item label="客户名称" prop="customerName">
            <el-input v-model="agreementObj.customerName"></el-input>
          </el-form-item>
          <el-form-item label="授权数量" prop="authNum">
            <el-input-number
                v-model="agreementObj.authNum"
                :min="1"
                label="授权数量"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="合同金额" prop="money">
            <el-input-number
                v-model="agreementObj.money"
                :min="0"
                label="合同金额"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="商务经理" prop="businessHandle">
            <el-input v-model="agreementObj.businessHandle"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input v-model="agreementObj.remark"></el-input>
          </el-form-item>
          <el-form-item label="合同附件" prop="agreementFile">
            <el-upload
                class="w-36 border-gray-400 border-dashed border text-center"
                action="#"
                :show-file-list="false"
                :http-request="logoUploader"
            >
              <span v-if="agreementObj.agreementFile" class="text-xs"
              >附件已上传,点击此处更换</span
              >
              <i
                  v-else
                  class="el-icon-plus text-xl justify-content-center align-items-center flex"
              ></i>
            </el-upload>
            <a
                v-if="agreementObj.agreementFile"
                class="text-xs"
                :href="agreementObj.agreementFile"
                target="_blank"
            >点击查看文件</a
            >
          </el-form-item>
          <el-form-item label="合同生效时间" prop="agreementDtm">
            <el-date-picker
                v-model="agreementObj.agreementDtm"
                type="date"
                placeholder="合同生效时间"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
import timeUtils from '@utils/timeUtils'
import oss from '@utils/oss'

export default {
  data () {
    return {
      agreementObj: {},
      rules: {
        agreementName: [
          {
            required: true,
            message: '请输入合同名称',
            trigger: 'blur'
          }
        ],
        agreementNo: [
          {
            required: true,
            message: '请输入合同编号',
            trigger: 'blur'
          }
        ],
        customerName: [
          {
            required: true,
            message: '请输入客户名称',
            trigger: 'blur'
          }
        ],
        businessHandle: [
          {
            required: true,
            message: '请输入商务经理',
            trigger: 'blur'
          }
        ],
        agreementDtm: [
          {
            required: true,
            message: '请选择合同生效时间',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const billNoList = this.agreementObj.billList ? this.agreementObj.billList.map(item => item.billNo) : []
          delete this.agreementObj.licenseBillProjectList
          delete this.agreementObj.createUserId
          delete this.agreementObj.billList
          this.$api.save('agreement', {
            ...this.agreementObj,
            agreementDtm: timeUtils.formatDay(new Date(this.agreementObj.agreementDtm)).join('-') + ' 00:00:00',
            billNoList
          }).then(() => {
            this.$message.success('保存成功')
            this.goBack()
          })
        }
      })
    },
    initData () {
      this.agreementObj = {
        agreementDtm: '',
        agreementFile: '',
        agreementName: '',
        authNum: 1,
        billNoList: [],
        billList: [],
        businessHandle: '',
        customerName: '',
        money: 0,
        remark: ''
      }
    },
    goBack () {
      this.$router.go(-1)
    },
    getData () {
      this.$api.select('agreement', this.$route.query.id).then(res => {
        this.agreementObj = res.data
      })
    },
    async logoUploader (item) {
      const { file } = item
      this.agreementObj.agreementFile = await oss.upload(file)
    }
  },
  mounted () {
    this.initData()
    if (this.$route.query.id) {
      this.getData()
    }
  }
}
</script>
